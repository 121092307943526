.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;  
  padding-left: 0;
  padding-right: 0;
  background-color: #FDFDFD;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 20px;
}

.dropdownBox {
  width: 100%; /* Ensures full width */
  height: 6vh; /* Adjust height for better proportion */
  background-color: #F4F4F4;
  display: flex; /* Align content inside dropdown box */
  align-items: center; /* Center the dropdown vertically */
  padding: 0 10px; /* Add some horizontal padding */
  margin-bottom: 20px;
  border-radius: 4px; /* Rounded edges for a smooth look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown {
  /* width: 100%; Makes the dropdown occupy the full width */
  height: 100%; /* Matches the parent box height */
  border: none; /* Removes default border */
  background: transparent; /* Ensures dropdown blends with parent box */
  font-size: 16px; /* Adjusts font size for better readability */
  color: #333; /* Dropdown text color */
  padding: 0 10px; /* Padding inside dropdown */
  outline: none; /* Removes outline on focus */
}


.generate-btn {
  background-color: #DCF5C2;
  color: white;
  padding: 10px 20px;
  margin-left: 20px;
  padding-left: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 20px 20px 20px 0px;
  border-radius: 100px;
}

.generate-btn,
.action-btn,
.report-btn {
  color: black; /* Sets the text color to black */
}

#viewbtn{
  color: rgb(98, 95, 95);
}
#generateButton{
  padding-left: 20px;
  
  /* margin-left: 20px; */
}

.generate-btn:hover {
  background-color: #45a049;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-btn {
  background-color: #673ab7;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-btn:hover {
  background-color: #5e35b1;
}

.subtitle {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

.report-list {
  list-style: none;
  padding: 0px 20px 10px 20px;
}

.report-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.report-details {
  display: flex;
  flex-direction: column;
}

.report-title {
  font-weight: bold;
  color: #333;
}

.report-date {
  padding-right: 2.5vw;
  font-size: 12px;
  color: #777;
}

.report-actions {
  display: flex;
  gap: 10px;
}

.report-btn {
  /* background-color: #673ab7; */
  color: white;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.report-btn:hover {
  background-color: #5e35b1;
}

.reportlistheads {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-template-columns: repeat(3, 15fr); /* Three equal columns */
  gap: 5vw; /* Optional: Adjust the spacing between grid items */
}

#generatedreports{
  font-size: 0.88em;
}

.slimLine{
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  box-sizing: border-box;
  height: 0.5vh;
  background-color: #E9ECEB;
}

/* Modal background */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.close-button {
  background-color: #ccc;
}

.share-confirm-button {
  background-color: #007bff;
  color: white;
}