.tabsContainer {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.10);
    
}
.hoverCusrsor{
    cursor: pointer;
}

.aggDetailsTab {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 14.66%;
    padding: 16px 30px;
    margin-bottom: -2px;
    gap: 10px;
}
.runAggDetailsTab {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20.66%;
    padding: 16px 30px;
    margin-bottom: -2px;
    gap: 10px;
}

.tabText {
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    /* font-family: $fontFamily; */
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

}

.tabText:hover {
    cursor: pointer;
}

.left-panel {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .heading {
        background: #1d3557;
        height: 35px;
        width: 100%;
        padding-left: 20px;
    }

    .first-heading {
        border-radius: 6px 6px 0 0;
        background: #1d3557;
        height: 35px;
        width: 100%;
        padding-left: 20px;
    }
    .second-heading {
        border-radius: 6px 6px 0 0;
        background: #1976d2;
        height: 35px;
        width: 100%;
        padding-left: 20px;
    }
}

.back_btn:hover {
    cursor: pointer;
}







.donation-major-areas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(33.333% - 20px);
    margin-bottom: 20px;
}

.name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.percentage {
    font-size: 32px;
    font-weight: bold;
}

/* .custom-progress{
/* background-color: #ccc; */
/* border-radius: 10px; */
    /* Grey color */
    /* Change thumb color to gradient */
    /* &::-webkit-progress-thumb {
        background-image: linear-gradient(right, #4e4ec9, #ff9900);
        border-radius: 10px;
        /* Gradient from red to green */
    
    
    /* Change track color */
    /* &::-webkit-progress-bar {
        background-color: #ccc;
        border-radius: 10px;
        
    } */
/* }  */



.dispurse{
    background-color: #ffffff;
    /* box-shadow: ; */
    box-shadow: 1px 1px 10px rgba(223, 219, 246, 1);
}
.cust-Btn{
    background-color: rgba(76, 73, 237, 1);
    border-radius: 50px;
    padding: 6px 15px;
    font-weight: 700;
    font-size: 14px;
    color: white;
    border: none;
    /* margin: 0; */
}