* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999;
  width: 8px;
  /* Set the desired z-index value */
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(86, 40, 210) !important;
  /* Color of the thumb */
  border-radius: 6px !important;
  /* Roundness of the thumb */
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-light: rgba(255, 255, 255, 0.6);
}

/* FORM */
.register {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* color: #ffffff; */
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://images.unsplash.com/photo-1510936111840-65e151ad71bb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=890&q=80"); */
  /* background-color: var(--color-bg);
  background-size: cover;
  background-blend-mode: darken; */
  font-family: "Josefin Sans", sans-serif;
}

.registerTitle {
  font-size: 50px;
}

.registerForm {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
}

.registerForm>label {
  margin: 10px 0;
  color: rgb(0, 0, 0);
}

.registerInput {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 10px;
}

.registerInput:focus {
  outline: none;
}

.registerButton {
  margin-top: 20px;
  cursor: pointer;
  background-color: #000000;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-align: center;
}

.registerButton:hover {
  background-color: #313131;
}
.requireField{
  color: red;
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate360 infinite 5s linear;
  /* Adjust the duration and timing function as needed */
}
.shimmer-effect {
  position: relative;
  overflow: hidden;
}

.shimmer-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  100% {
    left: 100%;
  }
}
@media screen and (max-width: 480px) {
  .register {
    background-position: right;
  }

  .registerInput {
    font-size: 15px;
    padding: 15px;
    border-radius: 8px;
  }

  .registerButton {
    font-size: 16px;
    padding: 20px;
    border-radius: 12px;
  }
}